const Footer = () => {
	const year = 2022;
	return (
		<>
			<footer className="text-center text-capitalize">
			<a href="https://www.google.com/search?client=firefox-b-1-d&q=otis+fuse+productions"> copyright otis fuse productions &copy; {year}</a>
			</footer>
		</>
	);
};

export default Footer;
