import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AiFillRobot } from "react-icons/ai";
import { FaRegEdit, FaNewspaper } from "react-icons/fa";
import { GiTalk } from "react-icons/gi";
import Meta from "../components/Meta";
import logo from "../img/logo.png";
import essayImage from "../img/essay.png";
import chatImage from "../img/chat.png";
import newsImage from "../img/news.png"; // Import your news image here

const Home = () => {
  return (
    <div>
      <Meta title={"Otisfuse | AI Essay Writer, Chat with Characters, and AI News"} />
      <Container fluid className="bg-primary text-white py-5">
        <Container>
          <Row>
            <Col md={6} className="d-flex align-items-center mb-3">
              <div>
                <h1 className="fw-bold mb-4">
                  Welcome to OtisFuse
                  <br />
                  Your One-Stop-Shop
                  <br />
                  For Everything AI
                </h1>
                <a href="https://chat.otisfuse.com" target="_blank" rel="noopener noreferrer">
                  <Button variant="outline-light" size="lg">
                    <GiTalk size={20} className="me-2" />
                    Chat with Characters
                  </Button>
                </a>
                <a href="https://essays.otisfuse.com" target="_blank" rel="noopener noreferrer">
                  <Button variant="outline-light" size="lg" className="me-3">
                    <AiFillRobot size={20} className="me-2" />
                    Try Our Essay Writer
                  </Button>
                </a>
                <a href="https://news.otisfuse.com" target="_blank" rel="noopener noreferrer">
                  <Button variant="outline-light" size="lg" className="me-3">
                    <FaNewspaper size={20} className="me-2" />
                    Discover AI News
                  </Button>
                </a>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={logo}
                alt="Otisfuse Logo"
                className="img-fluid rounded-3"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <hr />
      <Container className="py-5">
        <Row className="mb-5">
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={newsImage}
              alt="AI News"
              className="img
              fluid rounded-3"
              style={{ height: "500px" }}
            />
          </Col>
          <Col lg={6} className="mb-4 mb-lg-0 d-flex align-items-center">
            <div>
              <h2 className="fw-bold mb-4">AI News and Trends</h2>
              <p className="lead mb-4">
                Stay updated with the latest breakthroughs and insights in the world of AI. Our AI-written news not only informs you but also provides unique perspectives and insights into the ever-evolving AI landscape.
              </p>
              <a href="https://news.otisfuse.com" target="_blank" rel="noopener noreferrer">
              <Button variant="primary">
                <FaNewspaper size={20} className="me-2" />
                Read Now
              </Button>
              </a>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="mb-5">
          <Col lg={6} className="mb-4 mb-lg-0 d-flex align-items-center">
            <div>
              <h2 className="fw-bold mb-4">AI Essay Writer</h2>
              <p className="lead mb-4">
                Don't have the time or skills to write an essay? Otisfuse's AI
                Essay Writer can help! Simply provide the topic and let the
                algorithm take care of the rest. Our essay writer uses the
                latest in natural language processing and machine learning
                techniques to deliver high-quality essays in just minutes.
              </p>
              <a href="https://essays.otisfuse.com" target="_blank" rel="noopener noreferrer">
              <Button variant="primary">
                <FaRegEdit size={20} className="me-2" />
                Try Now
              </Button>
              </a>
            </div>
          </Col>
          <Col
            lg={6}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src={essayImage}
              alt="Essay Writing"
              className="img-fluid rounded-3"
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col
            lg={6}
            className="mb-4 mb-lg-0 d-flex align-items-center justify-content-center"
          >
            <img
              src={chatImage}
              alt="Chat with Characters"
              className="img-fluid rounded-3"
            />
          </Col>
          <Col lg={6} className="d-flex align-items-center">
            <div>
              <h2 className="fw-bold mb-4">Chat with Characters</h2>
              <p className="lead mb-4">
                Have you ever wanted to chat with your favorite historical
                figure or fictional character? Now you can with Otisfuse's Chat
                with Characters service. Our team of developers and writers have
                created a library of characters from various genres, including
                history, literature, and pop culture. You can have a
                conversation with characters such as Abraham Lincoln, Sherlock
                Holmes, or even Harry Potter. Our chatbot uses the latest in
                natural language processing and machine learning to provide a
                fun and engaging conversation with your chosen character.
              </p>
              <a href="https://chat.otisfuse.com" target="_blank" rel="noopener noreferrer">
              <Button variant="primary">
                <GiTalk size={20} className="me-2" />
                Start Chatting
              </Button>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Home;
