import { Link } from "react-router-dom";
import { Nav, Container, Image } from "react-bootstrap";
import otisfuse from '../img/of.png';
import { ChatDots, FileEarmarkText, ClockHistory, InfoCircle, Newspaper } from 'react-bootstrap-icons';

const Menu = () => {
  return (
    <Container>
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <Link
          to="/"
          className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none"
        >
        <Image src={otisfuse} alt="" rounded className="img-fluid me-2" style={{ width: "50px", height: "50px" }} />
        <span style={{ fontSize: "1.5rem" }}>OtisFuse AI</span>
        </Link>
        <Nav>
          <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
            <li>
              <Link to="https://chat.otisfuse.com" className="nav-link px-2 link-secondary">
                <ChatDots className="me-1" />
                Chat
              </Link>
            </li>
            <li>
              <Link to="https://news.otisfuse.com" className="nav-link px-2 link-secondary">
                <Newspaper className="me-1" />
                AI News
              </Link>
            </li>
            <li>
              <Link to="/essay" className="nav-link px-2 link-secondary">
                <FileEarmarkText className="me-1" />
                New Essay
              </Link>
            </li>
            <li>
              <Link to="/history" className="nav-link px-2 link-secondary">
                <ClockHistory className="me-1" />
                History
              </Link>
            </li>
            <li>
              <Link to="/about" className="nav-link px-2 link-secondary">
                <InfoCircle className="me-1" />
                About
              </Link>
            </li>
          </ul>
        </Nav>
      </header>
    </Container>
  );
};

export default Menu;
